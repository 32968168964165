import React, {Component} from 'react'
import map from 'lodash/map'
import { WidgetsPageLayout } from '../../components/layouts'
import { withPreview } from '../../components/common'
import { PreviewHelper } from '../../helpers'

class WidgetsPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            widgetsPage: null,
            sitemap: []
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('widgetsPage', this.props.location.search).then(entry => {
            this.initializeWidgetsPage(entry)
        })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    } 

    initializeWidgetsPage(entry) {
        const page = {
            __typename: 'ContentfulPageWidgets',
            ...entry.fields,
            sections: map(entry.fields.sections, section => ({
                ...section.fields,
                widgets: map(section.fields.widgets, widget => PreviewHelper.parse(widget))
            }))
        }

        this.setState({
            page
        })
    }

    render() {
        const { page, sitemap } = this.state
        const site = this.props.getSiteInfo()

        return (<WidgetsPageLayout page={page} site={site} sitemap={sitemap} />)
    }
}

export default withPreview(WidgetsPagePreview)